<template>
	<DialogBase
		:dialogWidth="800"
		:dialogModel="dialogModel"
		@closeDialog="val => closeDialog(val)"
		scrollable
		fullscreen
	>
		<template v-slot:title
			>{{
				selectedModel ? `Update ${selectedModel.payload.name}` : "Create New"
			}}
			Api Configurations</template
		>
		<v-form
			id="create-update-api-config-dialog"
			@submit.prevent
			v-model="isValidForm"
			ref="from"
		>
			<FormMaker
				@input="handleFormInputClick"
				@click="handleFormInputClick"
				:schema="form_structure"
				:model="form_data"
			>
				<!-- <div
			slot="slot-tooltip"
			slot-scope="slotProps"
		>
			Tooltip-Slot: {{ slotProps.obj.schema.tooltip }} has value '{{ slotProps.obj.value || 'undefined' }}'
		</div> -->
			</FormMaker>
		</v-form>

		<template v-slot:footer>
			<v-btn color="accent" outlined block @click="submit()">{{
				selectedModel ? "Update" : "Create"
			}}</v-btn>
		</template>
	</DialogBase>
</template>

<script>
	import { mapState } from "vuex";
	import { required, minLen } from "@/plugins/validationRules.js";
	import FormMaker from "@/components/FormMaker";
	import DialogBase from "@/components/dialogs/Base";
	export default {
		components: { DialogBase, FormMaker },
		props: {
			dialogModel: { required: true, type: Boolean },
			selectedModel: { required: true, default: null }
		},
		data() {
			return {
				isValidForm: false,
				form_data: {
					isEnabled: "Yes",
					type: null,
					name: null,
					carrierName: null,
					apiValidStates: null,
					notes: null,
					refs: null,
					resultDescriptionPath: null,
					resultPremiumPath: null,
					resultCarrierUrlPath: null,
					credentials: []
				}
			};
		},
		mounted() {
			if (this.selectedModel) {
				let selectedModel = JSON.parse(
					JSON.stringify(Object.assign({}, this.selectedModel.payload))
				);

				selectedModel.credentials = [];

				selectedModel.credentials = this.selectedModel.payload.credentials.map(
					obj => {
						return {
							...obj,
							keyValue: obj.keyValue.map(obj1 => {
								return {
									value: obj1
								};
							})
						};
					}
				);

				this.form_data = Object.assign({}, selectedModel);
			} else {
				this.addNewCredentialField();
			}

			this.setCredentialFieldsStyle();
		},
		computed: {
			form_structure() {
				let structure = {
					isEnabled: {
						type: "btn-toggle",
						name: "isEnabled",
						label: "Is Api Enabled?",
						clearable: false,
						outlined: true,
						solo: true,
						text: true,
						hideDetails: false,
						class: "custom-style",
						color: "primary",
						elevation: 0,
						options: ["Yes", "No"],
						dense: true,
						col: { cols: 12, sm: 12, md: 12, lg: 6, xl: 6 },
						mandatory: false,
						rules: [required]
					},
					type: {
						type: "select",
						name: "type",
						label: "Application type",
						items: this.$store.state.application.application_types,
						multiple: false,
						returnObject: false,
						...this.$helpers.formFieldsBasicLayout,
						col: { cols: 12, sm: 12, md: 12, lg: 6, xl: 6 },
						rules: [required("Field is required.")]
					},
					name: {
						type: "text",
						name: "name",
						label: "Api Name",
						...this.$helpers.formFieldsBasicLayout,
						col: { cols: 12, sm: 12, md: 12, lg: 6, xl: 6 },
						rules: [required("Field is required.")]
					},
					carrierName: {
						type: "text",
						name: "carrierName",
						label: "Api Carrier Name",
						...this.$helpers.formFieldsBasicLayout,
						col: { cols: 12, sm: 12, md: 12, lg: 6, xl: 6 },
						rules: [required("Field is required.")]
					},
					apiValidStates: {
						type: "text",
						name: "apiValidStates",
						label: "Api Valid States",
						...this.$helpers.formFieldsBasicLayout,
						col: { cols: 12, sm: 12, md: 12, lg: 12, xl: 12 },
						rules: []
					},
					divider1: {
						type: "divider",
						class: "my-2",
						col: { cols: 12, sm: 12, md: 12, lg: 12, xl: 12 }
					},
					resultPremiumPath: {
						type: "text",
						name: "resultPremiumPath",
						label: "Result Premium Path",
						...this.$helpers.formFieldsBasicLayout,
						col: { cols: 12, sm: 12, md: 12, lg: 6, xl: 6 },
						rules: [required("Field is required.")]
					},
					resultDescriptionPath: {
						type: "text",
						name: "resultDescriptionPath",
						label: "Result Description Path",
						...this.$helpers.formFieldsBasicLayout,
						col: { cols: 12, sm: 12, md: 12, lg: 6, xl: 6 },
						rules: [required("Field is required.")]
					},
					resultCarrierUrlPath: {
						type: "text",
						name: "resultCarrierUrlPath",
						label: "Result Carrier Url",
						...this.$helpers.formFieldsBasicLayout,
						col: { cols: 12, sm: 12, md: 12, lg: 6, xl: 6 },
						rules: []
					},
					divider2: {
						type: "divider",
						class: "my-2",
						col: { cols: 12, sm: 12, md: 12, lg: 12, xl: 12 }
					},
					addConditionalFields: {
						type: "btn",
						name: "addConditionalFields",
						label: "Add New Field",
						dark: true,
						block: true,
						outlined: true,
						class: "custom-style mt-n2",
						color: "success",
						col: { cols: 12, sm: 12, md: 12, lg: 12, xl: 12 }
					},
					divider3: {
						type: "divider",
						class: "my-2",
						col: { cols: 12, sm: 12, md: 12, lg: 12, xl: 12 }
					},
					credentials: {
						type: "array",
						col: { cols: 12, sm: 12, md: 12, lg: 12, xl: 12 },
						class: "form-section",
						schema: {
							conditional: {
								type: "btn-toggle",
								name: "conditional",
								label: "Is Conditional?",
								clearable: false,
								outlined: true,
								solo: true,
								text: true,
								hideDetails: false,
								class: "custom-style",
								color: "primary",
								elevation: 0,
								options: ["Yes", "No"],
								dense: true,
								hidden: true,
								col: { cols: 12, sm: 12, md: 12, lg: 6, xl: 6 },
								mandatory: false,
								rules: [required]
							},
							multiValue: {
								type: "btn-toggle",
								name: "multiValue",
								label: "Is Multi Value?",
								clearable: false,
								outlined: true,
								solo: true,
								text: true,
								hideDetails: false,
								class: "custom-style",
								color: "primary",
								elevation: 0,
								options: ["Yes", "No"],
								dense: true,
								col: { cols: 12, sm: 12, md: 12, lg: 4, xl: 4 },
								mandatory: false,
								rules: [required]
							},
							roundRobin: {
								type: "btn-toggle",
								name: "roundRobin",
								label: "Is Round Robin Value?",
								clearable: false,
								outlined: true,
								solo: true,
								text: true,
								hideDetails: false,
								class: "custom-style if-multi-value-attribute",
								color: "primary",
								elevation: 0,
								options: ["Yes", "No"],
								dense: true,
								col: { cols: 12, sm: 12, md: 12, lg: 4, xl: 4 },
								mandatory: false,
								rules: [required]
							},
							conditions: {
								type: "array",
								col: { cols: 12, sm: 12, md: 12, lg: 12, xl: 12 },
								class: "form-sub-section",
								schema: {
									addNewConditionBtn: {
										type: "btn",
										name: "addNewConditionBtn",
										label: ` + `,
										dark: true,
										block: true,
										outlined: true,
										class: "custom-style",
										color: "primary",
										col: { cols: 12, sm: 12, md: 1, lg: 1, xl: 1 }
									},
									applicationPayloadKeyName: {
										type: "text",
										name: "applicationPayloadKeyName",
										label: "Condition Field Name",
										...this.$helpers.formFieldsBasicLayout,
										class: "custom-style ",
										col: { cols: 12, sm: 12, md: 4, lg: 4, xl: 4 },
										rules: [required("Field is required.")]
									},
									operatorSign: {
										type: "btn",
										name: "operatorSign",
										label: ` = `,
										dark: true,
										block: true,
										outlined: true,
										class: "custom-style",
										color: "success",
										col: { cols: 12, sm: 12, md: 2, lg: 2, xl: 2 }
									},
									applicationPayloadKeyValue: {
										type: "text",
										name: "applicationPayloadKeyValue",
										label: "Condition Field Value",
										...this.$helpers.formFieldsBasicLayout,
										class: "custom-style ",
										col: { cols: 12, sm: 12, md: 4, lg: 4, xl: 4 },
										rules: [required("Field is required.")]
									},
									removeConditionBtn: {
										type: "btn",
										name: "removeConditionBtn",
										label: ` - `,
										dark: true,
										block: true,
										outlined: true,
										class: "custom-style",
										color: "error",
										col: { cols: 12, sm: 12, md: 1, lg: 1, xl: 1 }
									}
								}
							},
							keyName: {
								type: "text",
								name: "keyName",
								label: "Field Name",
								...this.$helpers.formFieldsBasicLayout,
								col: { cols: 12, sm: 12, md: 12, lg: 6, xl: 6 },
								rules: [required("Field is required.")]
							},
							keyValue: {
								type: "array",
								col: { cols: 12, sm: 12, md: 12, lg: 6, xl: 6 },
								class: "form-sub-section contains-add-remove-icons",
								schema: {
									value: {
										type: "text",
										name: "keyValue",
										label: "Field Value",
										...this.$helpers.formFieldsBasicLayout,
										class: `${this.$helpers.formFieldsBasicLayout} has-outer-icon`,
										prependIcon: "mdi-close",
										appendOuterIcon: "mdi-plus",
										col: { cols: 12, sm: 12, md: 12, lg: 12, xl: 12 },
										rules: [required("Field is required.")]
									}
								}
							},
							removeConditionalFields: {
								type: "btn",
								name: "removeConditionalFields",
								label: "Remove This Field",
								dark: true,
								block: true,
								outlined: true,
								class: "custom-style mt-1",
								color: "error",
								col: { cols: 12, sm: 12, md: 12, lg: 12, xl: 12 }
							},
							addConditionalFields: {
								type: "btn",
								name: "addConditionalFields",
								label: "Add Conditional Field",
								dark: true,
								block: true,
								outlined: true,
								class: "custom-style mt-n2",
								color: "success",
								col: { cols: 12, sm: 12, md: 12, lg: 12, xl: 12 }
							}
						}
					},
					notes: {
						type: "textarea",
						name: "notes",
						label: "Notes",
						rows: 2,
						...this.$helpers.formFieldsBasicLayout,
						rules: []
					},
					refs: {
						type: "textarea",
						name: "refs",
						label: "References",
						rows: 2,
						...this.$helpers.formFieldsBasicLayout,
						rules: []
					}
				};

				return structure;
			}
		},
		methods: {
			closeDialog(val = false) {
				this.$emit("closeDialog", val);
			},
			async handleFormInputClick({
				on,
				id,
				key,
				value,
				params,
				obj,
				data,
				schema,
				parent,
				index,
				event
			}) {
				// console.log(
				// 	on,
				// 	id,
				// 	key,
				// 	value,
				// 	params,
				// 	obj,
				// 	data,
				// 	schema,
				// 	parent,
				// 	index,
				// 	event
				// );
				// console.log(`${on} ${id} ${key} ${value}`);
				// console.log(on + "," + id + "," + key + "," + params.tag);
				if (
					`${on + "," + id + "," + key + "," + params.tag}` ==
					`click,form-base-credentials-${index[0]}-conditions-${index[1]},addNewConditionBtn,button`
				) {
					this.$nextTick(function() {
						this.addNewCondition(index[0], index[1]);
					});
				}

				if (
					`${on + "," + id + "," + key + "," + params.tag}` ==
					`click,form-base-credentials-${index[0]}-conditions-${index[1]},removeConditionBtn,button`
				) {
					this.$nextTick(function() {
						if (this.form_data.credentials[index[0]].conditions.length > 1) {
							this.removeCondition(index[0], index[1]);
						}
					});
				}

				if (
					`${on + "," + id + "," + key + "," + params.tag}` ==
					`click,form-base,addConditionalFields,button`
				) {
					this.$nextTick(function() {
						this.addNewCredentialField(null);
					});
				}

				if (
					`${on + "," + id + "," + key + "," + params.tag}` ==
					`click,form-base-credentials-${index[0]},addConditionalFields,button`
				) {
					this.$nextTick(function() {
						this.addNewCredentialField(index[0]);
					});
				}

				if (
					`${on + "," + id + "," + key + "," + params.tag}` ==
						`click,form-base-credentials-${index[0]},keyName,prepend` ||
					`${on + "," + id + "," + key + "," + params.tag}` ==
						`click,form-base-credentials-${index[0]},removeConditionalFields,button`
				) {
					this.$nextTick(function() {
						this.removeCredentialField(index[0]);
					});
				}

				if (
					`${on} ${id} ${key} ${value}` ==
					`input form-base-credentials-${index[0]} multiValue No`
				) {
					this.form_data.credentials[index[0]].keyValue.splice(
						1,
						this.form_data.credentials[index[0]].keyValue.length - 1
					);
				}

				if (
					`${on} ${id} ${key} ${value}` ==
					`input form-base-credentials-${index[0]} multiValue Yes`
				) {
				}

				if (
					`${on + "," + id + "," + key + "," + params.tag}` ==
					`click,form-base-credentials-${index[0]}-keyValue-${index[1]},value,append-outer`
				) {
					this.form_data.credentials[index[0]].keyValue.push({
						value: null
					});
				}
				if (
					`${on + "," + id + "," + key + "," + params.tag}` ==
					`click,form-base-credentials-${index[0]}-keyValue-${index[1]},value,prepend`
				) {
					if (this.form_data.credentials[index[0]].keyValue.length > 1) {
						this.form_data.credentials[index[0]].keyValue.splice(index[1], 1);
					}
				}

				if ((on + "," + key).toString().includes("input,conditional")) {
					if (value?.toString() == "Yes") {
						this.$nextTick(function() {
							this.addConditionsToCredentialField(index[0]);
						});
					}
					if (value?.toString() == "No") {
						this.$nextTick(function() {
							this.removeConditionsToCredentialField(index[0]);
						});
					}
				}

				this.$nextTick(function() {
					this.setCredentialFieldsStyle();
				});
			},
			addNewCredentialField(index = null) {
				if (typeof index !== "undefined" && index !== null) {
					let allCredentials = this.$helpers.arrayInsert(
						this.form_data.credentials,
						index + 1,
						{
							conditional: "Yes",
							multiValue: "No",
							roundRobin: "No",
							conditions: [
								{
									applicationPayloadKeyName: null,
									applicationPayloadKeyValue: null
								}
							],
							keyName: this.form_data?.credentials[index]?.keyName,
							keyValue: [
								{
									value: null
								}
							]
						}
					);
					this.form_data.credentials = allCredentials;
				} else {
					this.form_data.credentials.push({
						conditional: "No",
						multiValue: "No",
						roundRobin: "No",
						conditions: [],
						keyName: null,
						keyValue: [
							{
								value: null
							}
						]
					});
				}
			},
			addNewCondition(index = null, index1 = null) {
				if (
					typeof index !== "undefined" &&
					index !== null &&
					typeof index1 !== "undefined" &&
					index1 !== null
				) {
					let allConditions = this.$helpers.arrayInsert(
						this.form_data.credentials[index].conditions,
						index1 + 1,
						{
							applicationPayloadKeyName: null,
							applicationPayloadKeyValue: null
						}
					);
					this.form_data.credentials[index].conditions = allConditions;
				} else {
					this.form_data.credentials[index].conditions.push({
						applicationPayloadKeyName: null,
						applicationPayloadKeyValue: null
					});
				}
			},
			removeCondition(index = null, index1 = null) {
				if (
					typeof index !== "undefined" &&
					index !== null &&
					typeof index1 !== "undefined" &&
					index1 !== null
				) {
					this.form_data.credentials[index].conditions.splice(index1, 1);
				}
			},
			setCredentialFieldsStyle() {
				let $this = this;
				this.$nextTick(function() {
					$this.form_data?.credentials?.forEach((credField, index) => {
						let element = document.querySelector(
							`#form-base-credentials-${index}`
						);
						let elementDefaultClassList = "form-section"; //element.getAttribute("class");
						let elementNewClassList = `${elementDefaultClassList}`;

						if (credField.multiValue == "Yes") {
							elementNewClassList = `${elementNewClassList} is-multi-value-attribute`;
						} else {
							elementNewClassList = `${elementNewClassList} is-not-multi-value-attribute`;
						}

						if (credField.conditional == "Yes") {
							elementNewClassList = `${elementNewClassList} is-conditional-value-attribute`;
						} else {
							elementNewClassList = `${elementNewClassList} is-not-conditional-value-attribute`;
						}

						element.parentElement.setAttribute("class", elementNewClassList);
					});
				});
			},
			removeCredentialField(index) {
				this.form_data.credentials.splice(index, 1);
			},
			addConditionsToCredentialField(index) {
				this.form_data.credentials[index].conditions = [
					{
						applicationPayloadKeyName: null,
						applicationPayloadKeyValue: null
					}
				];
			},
			removeConditionsToCredentialField(index) {
				this.form_data.credentials[index].conditions = [];
			},
			async submit() {
				this.$refs.from.validate();
				if (!this.isValidForm) return;

				let formData = JSON.parse(
					JSON.stringify(Object.assign({}, this.form_data))
				);
				formData["credentials"] = formData.credentials.map(obj => {
					return {
						...obj,
						keyValue: this.$helpers.pluck(obj.keyValue, "value")
					};
				});

				let data = {
					api_config_id: this.selectedModel?.id,
					user_id: this.currentUser?.id,
					payload: formData
				};

				return await this.post(
					this.baseUrl + `/add-new-or-update-api-configuration`,
					data,
					true
				).then(data => {
					if (data) {
						this.showMessageFromResponseDta(data);

						if (data.code == 200) {
							this.$emit("created", data.data.config);
						}
					}
				});
			}
		}
	};
</script>

<style lang="scss">
	#create-update-api-config-dialog {
		.field-container {
			padding: 5px !important;
		}
		.form-section {
			& * {
				background-color: transparent !important;
			}
			margin: 20px !important;
			padding: 20px !important;
			border: solid 1px rgb(199, 199, 199);
			border-radius: 10px !important;
		}
		.form-sub-section {
			margin: 10px !important;
			padding: 10px !important;
			background-color: rgba(170, 170, 170, 0.1) !important;
			border-radius: 10px !important;
		}

		.is-not-multi-value-attribute {
			.if-multi-value-attribute {
				display: none !important;
			}
			.contains-add-remove-icons {
				.v-input__icon--prepend,
				.v-input__icon--append-outer {
					display: none !important;
				}
			}
		}

		.is-not-conditional-value-attribute {
			background-color: rgba(173, 255, 228, 0.1) !important;
			.container-removeConditionalFields {
				display: none !important;
			}
		}
		.is-conditional-value-attribute {
			background-color: rgba(224, 255, 173, 0.1) !important;
			.container-addConditionalFields {
				display: none !important;
			}
		}

		.has-outer-icon {
			.v-input__icon--prepend {
				&,
				* {
					font-size: 15px;
					color: rgb(241, 13, 13) !important;
				}
				border: solid 1px rgb(241, 13, 13) !important;
				border-radius: 50% !important;
			}
			.v-input__icon--append-outer {
				&,
				* {
					font-size: 15px;
					color: rgb(0, 172, 0) !important;
				}
				border: solid 1px rgb(0, 172, 0) !important;
				border-radius: 50% !important;
			}
		}
	}
</style>
